var settings = require('gw/settings'),
    utils = require('gw/utils');

console.info(settings.appName + ' v' + settings.version);

module.exports = {
    showCapabilities: showCapabilities
};

function showCapabilities() {
    var vm = new ViewModel();
    vm.getApps();
    ko.applyBindings(vm, document.getElementById('capabilities'));

    function ViewModel() {
        var self = this;

        self.capabilities = ko.observableArray([]);
        self.isLoading = ko.observable(true);

        self.getApps = function() {
            utils.getApplications()
                .then(function(data) {
                    self.capabilities(data);
                })
                .catch(function(jqXHR) {
                    var err = utils.extractErrors(jqXHR);
                    toastr.error(err.message);
                })
                .finally(function() {
                    self.isLoading(false);
                });
        };
    }
}