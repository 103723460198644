var settings = {
    apiUrl: '',
    appName: '',
    baseUrl: '',
    commonApiToken: '',
    commonApiUrl: '',
    gridPageSize: 25,
    gwBaseUrl: '',
    noResultsMsg: 'No results to display',
    pendingDataMsg: 'Searching...',
    version: '',
    storage: {
        gwApps: 'gwApps'
    }
};

GW = GW || (GW = {});
App = App || (App = {});
$.extend(settings, GW.settings, App.settings);

module.exports = settings;
