/*
 * Dedicated binding instead of negating the visible binding.
 * SEE: http://stackoverflow.com/a/10573792
 */
ko.bindingHandlers.hidden = {
    update: function(element, valueAccessor) {
        ko.bindingHandlers.visible.update(element, function() {
            return !ko.utils.unwrapObservable(valueAccessor());
        });
    }
};

/*
 * Provide custom binding for Bootstrap modal dialog windows.
 * SEE: http://jsfiddle.net/hcL4s/
 * SEE: https://groups.google.com/d/topic/knockoutjs/ZYojLIW9PlE/discussion
 */
ko.bindingHandlers.bootstrapModal = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var props = valueAccessor(),
            vm = bindingContext.createChildContext(viewModel);

        ko.utils.extend(vm, props);

        vm.close = function() {
            var form = $(element).find('form'),
                validator = form.data('validator'),
                realErrorClass = 'has-error';

            // Clear form fields and jQuery Validate errors
            form[0].reset();

            // Make sure an attempt has been made to validate the form.
            if (typeof validator !== 'undefined' && validator != null) {
                validator.resetForm();
                // Remove validation errors from UI
                $("." + validator.settings.errorClass)
                    .tooltip('destroy')
                    .closest('.form-group')
                    .removeClass(validator.settings.errorClass + ' ' + realErrorClass);
            }

            vm.show(false);
            vm.onClose();
        };
        vm.action = function(form) {
            vm.onAction(form);
        };
        ko.utils.toggleDomNodeCssClass(element, 'modal', true);
        ko.renderTemplate(vm.template, vm, null, element);

        // Enable validation on all form fields now that
        // the template has been added to the DOM.
        // SEE: http://nadeemkhedr.wordpress.com/2012/08/27/how-the-unobtrusive-jquery-validate-plugin-works-internally-in-asp-net-mvc/
        $.validator.unobtrusive.parse($(element).find('form'));

        var showHide = ko.computed(function() {
            $(element).modal(vm.show() ? 'show' : 'hide');
        });

        return {
            controlsDescendantBindings: true
        };
    }
};

/*
 * Allow a section to "opt-out" of being included in parent bindings.
 * Usage:
 *      <!-- ko stopBinding: true -->
 *      <p data-bind="someValue"></p>
 *      <!-- /ko -->
 * SEE: http://www.knockmeout.net/2012/05/quick-tip-skip-binding.html
 */
ko.bindingHandlers.stopBinding = {
    init: function() {
        return { controlsDescendantBindings: true };
    }
};

ko.virtualElements.allowedBindings.stopBinding = true;
