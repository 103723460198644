var settings = require('gw/settings');

module.exports = {
    configureRequest: configureRequest,
    extractErrors: extractErrors,
    getApplications: getApplications,
    parseBoolean: parseBoolean,
    resizeKendoGrid: resizeKendoGrid,
    windowpop: windowpop
};

/**
 * Returns a boolean from a string representation.
 * @param {string} str String representation of boolean value.
 * @returns {Boolean} Boolean equivalent of string.
 */
function parseBoolean(str) {
    return (/^true$/i).test(str);
}

/**
 * Opens a popup window.
 * @param {string} url
 * @param {number} width
 * @param {number} height
 */
function windowpop(url, width, height) {
    var leftPosition, topPosition;
    //Allow for borders.
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    //Allow for title and status bars.
    topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    //Open the window.
    window.open(url, "Window2", "status=no,height=" + height + ",width=" + width +
        ",resizable=yes,left=" + leftPosition + ",top=" + topPosition +
        ",screenX=" + leftPosition + ",screenY=" + topPosition +
        ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
}

/**
 * Extracts the error message from an XHR response.
 * @param {Object} xhr
 * @returns {string} Error message.
 * @see {@link http://stackoverflow.com/q/20294436}
 */
function extractErrors(xhr) {
    var err = new Error();
    err.message = xhr.statusText;
    err.responseText = xhr.responseText;
    err.status = xhr.status;
    err.statusText = xhr.statusText;
    if (xhr.responseText) {
        try {
            var responseObj = JSON.parse(xhr.responseText);
            if (responseObj.ExceptionMessage) {
                err.message = responseObj.ExceptionMessage;
            } else if (responseObj.InnerException) {
                err.message = responseObj.InnerException.Message;
            } else if (responseObj.Message) {
                err.message = responseObj.Message;
            } else {
                err.message = xhr.responseText;
            }
        } catch (e) {
        }
    }
    return err;
}

/**
 * Configures the XHR request before sending.
 * @param {Object} xhr
 */
function configureRequest(xhr) {
    xhr.setRequestHeader('Authorization', 'Session ' + settings.commonApiToken);
}

/*
 * Resizes a Kendo UI grid to fill the viewport.
 * Note that elements with percentage height require a parent element with 
 * an explicit height. This requirement applies recursively until an element 
 * with a pixel height is reached, or until the html element is reached. 
 * 100% high elements cannot have margins, paddings, borders or sibling elements.
 * @param {string} str Selector that grid is initialized from.
 * SEE: http://docs.kendoui.com/getting-started/web/grid/walkthrough#making-the-grid-100%-high-and-auto-resizable
 * SEE: http://blog.longle.net/2012/03/21/setting-teleriks-html5-kendoui-grids-height-to-100-when-binding-to-remote-data/
 */
function resizeKendoGrid(gridSelector) {
    var gridElement = $(gridSelector),
        // Get elements of grid and their height outside the data area
        otherElements = gridElement.children().not('.k-grid-content'),
        otherElementsHeight = 0,
        // Get height of area grid can be placed inside of
        newHeight = getKendoGridHeight(gridSelector);

    otherElements.each(function() {
        otherElementsHeight += $(this).outerHeight();
    });

    gridElement.children('.k-grid-content').height(newHeight - otherElementsHeight);
    gridElement.height(newHeight);
}

/*
 * Gets the target height of a Kendo grid based on sibling elements.
 * @param {string} str Selector that grid is initialized from.
 */
function getKendoGridHeight(gridSelector) {
    var gridElement = $(gridSelector),
        contentArea = gridElement.parent().height(),
        siblings = gridElement.parent().siblings(),
        siblingsHeight = 0;

    // Get height of grid siblings (including margins)
    siblings.each(function() {
        siblingsHeight += $(this).outerHeight(true);
    });

    return contentArea - siblingsHeight;
}

/*
 * Returns a promise representing a grouped list of Global Warranty applications.
 * @returns {Object} Object representing the list of applications grouped by capability.
 */
function getApplications() {
    var gwApps = null,
        supportsSessionStorage = Modernizr.sessionstorage == true;

    if (supportsSessionStorage) {
        gwApps = JSON.parse(sessionStorage.getItem(settings.storage.gwApps));
    }

    return Q.promise(function(resolve, reject) {
        if (gwApps == null) {
            $.ajax({
                beforeSend: configureRequest,
                data: {
                    '$orderby': 'Capability,Name',
                    '$select': 'Capability,IconClass,Name,Url'
                },
                url: settings.commonApiUrl + 'applications'
            })
                .then(function(data) {
                    var apps = data.Items;

                    if (supportsSessionStorage) {
                        sessionStorage.setItem(settings.storage.gwApps, JSON.stringify(apps));
                    }

                    resolve(groupByCapability(apps));
                }, function(jqXHR) {
                    delete jqXHR.then;
                    reject(jqXHR);
                });

        } else {
            resolve(groupByCapability(gwApps));
        }
    });
}

/*
 * Group applications by capability.
 * @param {Array} data Array of applications.
 * @returns {Array} List of applications grouped by capability.
 */
function groupByCapability(data) {
    var apps = [],
        capabilities = _.uniq(_.map(data, 'Capability')),
        idx = 0;

    capabilities.forEach(function(name) {
        var item = _.where(data, { 'Capability': name }),
            isLast = idx === capabilities.length - 1,
            capability = {
                name: name,
                apps: item,
                isLast: isLast
            };

        idx += 1;
        apps.push(capability);
    });

    return apps;
}
